<template>
    <modal @close="close">
        <template #title>{{ 'Liste aller Bestellungen im ausgewählten Zeitraum für' }} <span class="text-primary">{{$store.state.restaurant.locations.find(l => l.uuid === location).generalTitle}}</span></template>
        <template #interactive>
            <button-b :loading="loading" @click.native="download" class="bg-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                {{ $t('dashboard.download') }}
            </button-b>
        </template>
        <template #body>
            <strong class="text-base" v-if="loading">Ihre Liste wird gerade erstellt. Bitte warten Sie einen Moment.</strong>
            <div class="grid grid-cols-6 gap-6 mt-6">
                <div class="col-span-6">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_timefrom') }}</label>
                    <date-picker-block v-model="from">
                        <template #icon>
                            <icon-calendar/>
                        </template>
                        </date-picker-block>
                </div>
                <div class="col-span-6">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_timeto') }}</label>
                    <date-picker-block v-model="to">
                        <template #icon>
                            <icon-calendar/>
                        </template>
                        </date-picker-block>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ButtonB from '@/components/ui/ButtonB.vue'
import Modal from '@/components/dashboard/Modal.vue'
import DatePickerBlock from '@/components/ui/DatepickerBlock.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import FileSaver from 'file-saver'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            from: '',
            to: '',
            loading: false
        }
    },
    props: {
        location: {
            type: String
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        async download () {
            if (this.from !== '' && this.to !== '') {
                this.loading = true
                const csv = await this.restaurant.getCSV(this.from, this.to, this.location)
                const blob = new Blob(['\uFEFF' + csv.data], { type: 'text/csv;charset=utf-8;' })
                FileSaver.saveAs(blob, 'order.csv')
                this.loading = false
                this.close()
            }
        },
        close () {
            this.$emit('close')
        }
    },
    components: {
        ButtonB,
        Modal,
        DatePickerBlock,
        IconCalendar
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
