<template>
    <modal @close="close">
        <template #title>
            <div v-if="location === '' && !terminated">
                {{ $t('dashboard.archive_all_oneLocation_notTerminated') }}
            </div>
            <div v-if="location === '' && terminated">
                {{ $t('dashboard.archive_all_oneLocation_terminated') }}
            </div>
            <div v-if="location && !terminated">
                {{ $t('dashboard.archive_all_multipleLocations_notTerminated') }} <span class="text-primary">{{$store.state.restaurant.locations.find(l => l.uuid === location).generalTitle }}</span> {{$t('dashboard.archive?') }}
            </div>
            <div v-if="location && terminated">
                {{ $t('dashboard.archive_all_multipleLocations_terminated') }} <span class="text-primary">{{$store.state.restaurant.locations.find(l => l.uuid === location).generalTitle }}</span> {{$t('dashboard.archive?') }}
            </div>
        </template>
        <template #body>
            <button-b @click.native="confirmDeletion" :loading="loading" class="bg-primary">{{$t('dashboard.archive') }}</button-b>
            <button-b @click.native="close" class="bg-gray-600 ml-2" style="float: center">{{ $t('dashboard.cancel') }}</button-b>
        </template>
    </modal>
</template>

<script>
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            valueStatus: null,
            orderList: {
                orders: []
            }
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        confirmDeletion () {
            this.$emit('confirmArchive')
        }
    },
    components: {
        ButtonB,
        Modal

    },
    props: {
        location: {
            type: String
        },
        terminated: {
            type: Boolean
        },
        loading: {
            type: Boolean
        }
    }
}
</script>
