<template>
  <dashboard-sidebar>
  <template #over-content>
    <title-bar>
      <template #title>
            <div class="block float-left">
            {{ $t('dashboard.overview') }}
            </div>
            <div class="block float-right">
                <div v-if="length !== 0 && !terminated" class="flex justify-end hover:text-primary cursor-pointer" @click="modal=true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                    </svg>
                    <span class="text-base items-center w-full justify-center inline-flex">{{ $t('dashboard.archive_all_orders') }}</span>
                </div>
                <div v-else-if="lengthTimed !== 0 && terminated" class="flex justify-end hover:text-primary cursor-pointer" @click="modal=true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                    </svg>
                    <span class="text-base items-center w-full justify-center inline-flex">{{ $t('dashboard.archive_all_orders') }}</span>
                </div>
            </div>
            <div v-if="activeLocations.length > 1 && location != ''" ><br/>
                <div class="block mt-10">
                    <div v-if="!terminated" class="flex">
                        <strong class="mb-5 text-black text-2xl font-bold">{{ $t('dashboard.orders_for') }} <strong class="text-primary">{{activeLocations.find(x => x.uuid === location).generalTitle}}</strong></strong>
                    </div>
                    <div v-if="terminated" class="flex">
                        <strong class="mb-5 text-2xl font-bold text-black">{{ $t('dashboard.terminated_orders_for') }} <strong class="text-primary">{{activeLocations.find(x => x.uuid === location).generalTitle}}</strong></strong>
                    </div>
                    <div v-if="location !== ''" @click="csvModal = true" class="flex justify-end cursor-pointer hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 h-5 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="text-lg">Exportieren</span>
                    </div>
                    <div @click="changeLocation" class="text-black hover:text-primary cursor-pointer block float-left">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-7 mt-2 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                        </svg>
                        <strong class="text-lg">
                        {{ $t('dashboard.change_location') }}
                        </strong>
                    </div>
                    <div v-if="!terminated" @click="terminated = !terminated" class="text-black hover:text-primary cursor-pointer block float-right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <strong class="text-lg">
                        {{ $t('dashboard.show_terminated_orders') }} <span :class="{'text-red': lengthTimed !== 0, 'text-black': lengthTimed === 0}"> {{'(' + lengthTimed + ')'}}</span>
                        </strong>
                    </div>
                    <div v-if="terminated" @click="terminated = !terminated" class="text-black hover:text-primary cursor-pointer block float-right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <strong class="text-lg">
                        {{ $t('dashboard.show_recent_orders') }} <span :class="{'text-red': length !== 0, 'text-black': length === 0}"> {{'(' + length + ')'}}</span>
                        </strong>
                    </div>
                </div>
            </div>
            <div v-if="activeLocations.length === 1"><br/>
                <div v-if="!terminated" class="mt-10 block float-left">
                    <strong class="mb-5 text-black text-2xl font-bold">{{ 'Aktuelle Bestellungen' }}</strong>
                </div>
                <div v-if="terminated" class="mt-10 block float-left">
                    <strong class="mb-5 text-2xl font-bold text-black">{{ 'Terminierte Bestellungen' }}</strong>
                </div>
                <div v-if="location !== ''" @click="csvModal = true" class="flex justify-end cursor-pointer hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 h-5 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="text-lg">Exportieren</span>
                    </div>
                <div v-if="!terminated" @click="terminated = !terminated" class="mt-10 text-black hover:text-primary cursor-pointer block float-right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <strong class="text-lg">
                        {{ $t('dashboard.show_terminated_orders') }} <span :class="{'text-red': lengthTimed !== 0, 'text-black': lengthTimed === 0}"> {{'(' + lengthTimed + ')'}}</span>
                        </strong>
                    </div>
                    <div v-if="terminated" @click="terminated = !terminated" class="mt-10 text-black hover:text-primary cursor-pointer block float-right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <strong class="text-lg">
                        {{ $t('dashboard.show_recent_orders') }} <span :class="{'text-red': length !== 0, 'text-black': length === 0}"> {{'(' + length + ')'}}</span>
                        </strong>
                    </div>
            </div>
            <archive-all-orders v-if="modal" @close="modal=false" :location="location" :terminated="terminated" @confirmArchive="archiveAll" :loading="loading"/>
            <transition name="slide-fade">
            <export-csv v-if="csvModal" @close="csvModal=false" :location="location"/>
            </transition>
      </template>
    </title-bar>
  </template>
  <template #content>
        <div v-if="activeLocations.length > 1 && location === ''" class="block">
            <h4>
                {{ $t('dashboard.choose_location') }}
            </h4>
        </div>
        <div v-if="activeLocations.length > 1 && location === ''" class="grid gap-10 mt-14 grid-cols-3">
            <div v-for="loc in activeLocations.filter(l => l.active == true)" :key="loc.uuid" @click="location = loc.uuid" class="mr-4 border-2 rounded-lg  w-full cursor-pointer ">
                <div class="p-10 bg-gray-600 flex flex-col justify-between min-h-16 relative overflow-hidden rounded-lg  hover:opacity-95">
                    <div class="flex justify-between relative z-10">
                        <div class="">
                            <h4 class="text-white mb-4 text-2xl font-semibold">{{loc.generalTitle}}</h4>
                            <span class="text-white text-lg font-semibold">{{loc.generalZip}} {{loc.generalCity}}</span><br/>
                            <span class="text-white text-lg font-semibold">{{loc.generalStreet}} {{loc.generalStreetnum}}</span>
                        </div>
                    </div>
                    <div v-if="loc.media[0]">
                        <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg z-0 opacity-60" :src="loc.media[0].url"/>
                    </div>
                    <div v-else>
                        <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg z-0 opacity-60" src="https://images.unsplash.com/photo-1552566626-52f8b828add9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"/>
                    </div>
                </div>
            </div>
    </div>
    <div>
        <div v-if="location != '' && activeLocations.length > 1 && !terminated" >
            <div class="mt-5">
                <product-card v-for="order in ordersMultipleLocations" :key="order.uuid" :finalOrder="order" :location="location" :terminated="terminated"/>
            </div>
        </div>
        <div v-if="location != '' && activeLocations.length > 1 && terminated" >
            <div class="mt-5">
                <product-card v-for="order in terminatedOrdersMultipleLocations" :key="order.uuid" :finalOrder="order" :location="location" :terminated="terminated"/>
            </div>
        </div>
        <div v-if="activeLocations.length === 1 && !terminated" >
            <div class="mt-5">
                <product-card v-for="order in ordersOneLocation" :key="order.uuid" :finalOrder="order" :location="location" :terminated="terminated"/>
            </div>
        </div>
        <div v-if="activeLocations.length === 1 && terminated" >
            <div class="mt-5">
                <product-card v-for="order in terminatedOrdersOneLocation" :key="order.uuid" :finalOrder="order" :location="location" :terminated="terminated"/>
            </div>
        </div>
    </div>
    <strong v-if="length === 0 && !terminated && location !== ''">
        {{ $t('dashboard.no_orders') }}
    </strong>
    <strong v-if="lengthTimed === 0 && terminated && location !== ''">
        {{ $t('dashboard.no_terminated_orders') }}
    </strong>
  </template>
  </dashboard-sidebar>
</template>

<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import ProductCard from '@/components/dashboard/ProductCard.vue'
import TitleBar from '../../components/dashboard/TitleBar.vue'
// import { RenderedOrder, RenderedOrderList } from 'delivery-boosting-2021-model'
import { db, messaging } from '@/firebase'
import ArchiveAllOrders from '../../components/modals/ArchiveAllOrders.vue'
import ExportCsv from '@/components/modals/ExportCsv.vue'

export default {
    name: 'DashboardOverview',
    components: {
        DashboardSidebar,
        ProductCard,
        TitleBar,
        ArchiveAllOrders,
        ExportCsv
    },
    async created () {
        setInterval(() => { this.x = !this.x; this.$forceUpdate() }, 100)
        const token = await messaging.getToken({ vapidKey: 'BICklAKhvPWx8zTtzK-eOTq4xceV-4QTYi5_xGRYiNLIaqbtNuYUXBXKSY6pA0EBwQgnFGHM1-0FKPCgj48NiiA' })
        if (token) {
            console.log('token: ', token)
            await this.adminTokenCollection.doc(token).set({})
        } else {
            console.log('No registration token available. Request permission to generate one.')
        }
        messaging.onMessage(function (payload) {
            const content = {
                title: payload.notification.title,
                body: payload.notification.body,
                icon: payload.data.icon
            }
            console.log(content)
        })
    },
    methods: {
        async archiveAll () {
            this.loading = true
            if (this.activeLocations.length > 1) {
                if (!this.terminated) {
                    for (const o of this.renderedOrderList.orders.filter(x => x.location === this.location).filter(r => !r.deliveryTime || !r.timeType.timed)) {
                        if (o.state === 'FINISHED') {
                            await this.restaurant.updateOrderState(o, 'ARCHIVED')
                        }
                    }
                } else {
                    for (const o of this.renderedOrderList.orders.filter(x => x.location === this.location).filter(r => r.deliveryTime && r.timeType.timed)) {
                        if (o.state === 'FINISHED') {
                            await this.restaurant.updateOrderState(o, 'ARCHIVED')
                        }
                    }
                }
            } else if (this.activeLocations.length === 1) {
                if (!this.terminated) {
                    for (const o of this.renderedOrderList.orders.filter(r => !r.deliveryTime || !r.timeType.timed)) {
                        if (o.state === 'FINISHED') {
                            await this.restaurant.updateOrderState(o, 'ARCHIVED')
                        }
                    }
                } else {
                    for (const o of this.renderedOrderList.orders.filter(r => r.deliveryTime && r.timeType.timed)) {
                        if (o.state === 'FINISHED') {
                            await this.restaurant.updateOrderState(o, 'ARCHIVED')
                        }
                    }
                }
            }
            this.modal = false
            await this.$store.state.catalog.commit()
            this.loading = false
        },
        changeLocation () {
            this.location = ''
            this.terminated = false
        }
    },
    data () {
        return {
            renderedOrderList: {
                orders: []
            },
            modal: false,
            location: '',
            terminated: false,
            x: false,
            loading: false,
            csvModal: false
        }
    },
    computed: {
        activeLocations () {
            const locs = this.restaurant.locations.filter(l => l.active === true)
            if (locs.length === 1) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.location = locs[0].uuid
            }
            return this.restaurant.locations.filter(l => l.active === true)
        },
        restaurant () {
            return this.$store.state.restaurant
        },
        renderedOrders () {
            return this.renderedOrderList.orders
        },
        ordersMultipleLocations () {
            // eslint-disable-next-line no-unused-expressions
            this.x
            const orders = this.renderedOrders.filter(r => r.location === this.location).filter(r => (((r.deliveryTime.getTime() - new Date().getTime()) / 60000) <= 1440) || (!r.deliveryTime && !r.timeType.timed))
            return orders.sort(function (x, y) {
                return y.postedAt - x.postedAt
            })
        },
        terminatedOrdersMultipleLocations () {
            // eslint-disable-next-line no-unused-expressions
            this.x
            const orders = this.renderedOrders.filter(r => r.location === this.location).filter(r => (((r.deliveryTime.getTime() - new Date().getTime()) / 60000) > 1440))
            return orders.sort(function (x, y) {
                return y.postedAt - x.postedAt
            })
        },
        ordersOneLocation () {
            // eslint-disable-next-line no-unused-expressions
            this.x
            const orders = this.renderedOrders.filter(r => (((r.deliveryTime.getTime() - new Date().getTime()) / 60000) <= 1440) || (!r.deliveryTime && !r.timeType.timed))
            return orders.sort(function (x, y) {
                return y.postedAt - x.postedAt
            })
        },
        terminatedOrdersOneLocation () {
            // eslint-disable-next-line no-unused-expressions
            this.x
            const orders = this.renderedOrders.filter(r => (((r.deliveryTime.getTime() - new Date().getTime()) / 60000) > 1440))
            return orders.sort(function (x, y) {
                return y.postedAt - x.postedAt
            })
        },
        adminTokenCollection () {
            return db.doc(`restaurants/${this.$route.params.restaurantId}`).collection('adminTokens')
        },
        // eslint-disable-next-line vue/return-in-computed-property
        locationName () {
            if (this.location !== '') {
                return this.activeLocations.find(x => x.uuid === this.location).generalCity
            }
        },
        routeLocation () {
            return this.$route.params.location || ''
        },
        routeTerminated () {
            return this.$route.params.terminated || false
        },
        lengthTimed () {
            if (this.activeLocations.length > 1) {
                return this.terminatedOrdersMultipleLocations.length
            } else {
                return this.terminatedOrdersOneLocation.length
            }
        },
        length () {
            if (this.activeLocations.length > 1) {
                return this.ordersMultipleLocations.length
            } else {
                return this.ordersOneLocation.length
            }
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    this.renderedOrderList = await this.restaurant.getRenderedOrderList()
                } catch (error) {
                    console.log(error)
                }
            }
        },
        routeLocation: {
            immediate: true,
            handler () {
                if (this.routeLocation !== '') {
                    this.location = this.routeLocation
                }
            }
        },
        routeTerminated: {
            immediate: true,
            handler () {
                this.terminated = this.routeTerminated
            }
        }
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
